import React, {useState} from "react";
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import Routes from "../../utils/Routes";
import {useNavigation, useTranslation} from "../../misc/Hooks";
import {WebsiteMapPopup} from "../popup/WebsiteMapPopup";
import Button from "../misc/Button";

export default (props) => {

    const t = useTranslation();
    const navigation = useNavigation();
    const match = useRouteMatch();
    const lang = match.params.lang ?? process.env.REACT_APP_DEFAULT_LANG;

    const [showMenu, setShowMenu] = useState(false)
    const [showWebsiteMap, setShowWebsiteMap] = useState(false)

    const handlesShowMenu = () => {
        if(showMenu){
            setShowMenu(false)
        } else {
            setShowMenu(true)
        }
    }

    return (
            <nav className="navigation">

                <Button
                    className={'navigation__icon'}
                    img={'img/start/M_hover.png'}
                    imgHover={'img/start/M.png'}
                    onClick={handlesShowMenu}
                />
                <WebsiteMapPopup
                    onClick={() => setShowWebsiteMap(false)}
                    visible={showWebsiteMap}
                    className="-mapWebsite"
                />
                {!!showMenu && (
                    <ul>

                        <li><button onClick={()=> setShowWebsiteMap(true)}>{t("websiteMap")}</button></li>
                        <li><Link to={navigation(Routes.INSTRUCTION)}>{t("instruction")}</Link></li>
                        <li><Link to={navigation(Routes.CREATORS)}>{t("creators")}</Link></li>
                        <li><Link to={navigation(Routes.SPONSORS)}>{t("sponsors")}</Link></li>
                        {['pl'].includes(lang) && (
                            <li><Link to={navigation(Routes.FOR_TEACHERS)}>{t("forTeachers")}i</Link></li>
                        )}
                        {/*<li><Link to={Routes.RESEARCH}>{t("research")}</Link></li>*/}
                        <li><Link to={navigation(Routes.CONTACT)}>{t("contact")}</Link></li>

                    </ul>
                )}
            </nav>

    )
}
